declare global {
  interface Window {
    stratumn_env: Record<string, string>;
  }
}

export const ANALYTICS_API_URL: string = window.stratumn_env.ANALYTICS_API_URL;
export const ANALYTICS_UI_URL: string = window.stratumn_env.ANALYTICS_UI_URL;
export const ACCOUNT_API_URL: string = window.stratumn_env.ACCOUNT_API_URL;
export const TRACE_UI_URL: string = window.stratumn_env.TRACE_UI_URL;
export const ACCOUNT_UI_URL: string = window.stratumn_env.ACCOUNT_UI_URL;
export const CLIENT_ID: string = window.stratumn_env.CLIENT_ID;
export const VITE_ENV: string = window.stratumn_env.ENV;

export const ROUTE_DASHBOARD: string = '/';
export const ENV: string = window.stratumn_env.ENV;
